// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

require("jquery");
require("@nathanvda/cocoon");

require("stylesheets/application.scss");
const images = require.context("../images", true);

import flatpickr from "flatpickr";
require("flatpickr/dist/flatpickr.css");
require("trix");
require("@rails/actiontext");

Rails.start();
Turbolinks.start();
ActiveStorage.start();

document.addEventListener("turbolinks:load", () => {
  var fifteenDaysOut = new Date();
  var oneWeekOut = new Date();

  flatpickr("[data-behavior='flatpickr']", {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
    minDate: fifteenDaysOut.setDate(fifteenDaysOut.getDate() + 15),
  });

  flatpickr("#shopPicker", {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
    minDate: oneWeekOut.setDate(oneWeekOut.getDate() + 2),
  });

  flatpickr("[data-behavior='flatpickr_allowpast']", {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
    minDate: "2021-01-01",
  });

  const notiButton = document.getElementById("notification_close");
  if (notiButton) {
    notiButton.addEventListener("click", (e) => {
      const notifications = document.querySelectorAll(".notification");
      if (notifications.length > 0) {
        notifications.forEach((notif) => {
          notif.style.opacity = 0;
          setTimeout(() => {
            notif.remove();
          }, 500);
        });
      }
    });
  }

  $(".projects-section a.add_fields")
    .data("association-insertion-node", "#order_project_types")
    .data("association-insertion-method", "append");
  $(".documents-section a.add_fields")
    .data("association-insertion-node", "#order_documents")
    .data("association-insertion-method", "append");
  $(".beer-section a.add_fields")
    .data("association-insertion-node", "#beer_needs")
    .data("association-insertion-method", "append");
  $(".pop-section a.add_fields")
    .data("association-insertion-node", "#pop_needs")
    .data("association-insertion-method", "append");
  $(".install-section a.add_fields")
    .data("association-insertion-node", "#installed_items")
    .data("association-insertion-method", "append");
  $(".maintenance-section a.add_fields")
    .data("association-insertion-node", "#maintenance_items")
    .data("association-insertion-method", "append");

  $(document).on(
    "change",
    "#account_request_pop_required_true, #account_request_pop_required_false, #account_request_team_clean_install_true, #account_request_team_clean_install_false, #account_request_training_requested_true, #account_request_training_requested_false",
    function (e) {
      const $fieldGroup = $(e.target)
        .closest(".form-section")
        .find(".hide-first");
      if (e.target.value === true || e.target.value === "true") {
        $fieldGroup.slideDown();
        return;
      }
      $fieldGroup.slideUp();
    }
  );

  $(
    "#account_request_pop_required_true, #account_request_team_clean_install_true, #account_request_training_requested_true"
  ).trigger("change");

  $(".shop-thumbnails").on("click", "img", function () {
    var newSrc = $(this).attr("src");
    $(".shop-thumbnails .active").removeClass("active");
    $(this).addClass("active");
    $("#shopMainImage").attr("src", newSrc);
  });

  $("#variantContainer").on("change", "#productVariants", function () {
    var prices = $(this).data("variant-prices");
    var qty = $(this).data("variant-inventory");
    var selected = $("#productVariants option:selected").index();

    var newPrice = prices[selected];
    var newQty = qty[selected];
    if (newQty <= 0) {
      newQty = "This item is sold out.";
      $("#shopifyAdd").attr("disabled", "disabled");
      $(".pq").addClass("soldOut");
      $(".pq").text(newQty);
    } else {
      $("#shopifyAdd").attr("disabled", false);
      $(".pq").removeClass("soldOut");
      $(".pq").text("Quantity Available: " + newQty);
    }
    $("#shopifyPrice").text(newPrice);
    $("#itmQty").attr("max", newQty);
  });

  $("#qtyContainer").on("blur", "#itmQty", function (e) {
    var selected = $("#productVariants option:selected").index();
    var qty = $("#productVariants").data("variant-inventory");
    if (e.target.value > qty[selected]) {
      alert("Quantity higher than available inventory. Please try again.");
      $(this).val(0);
    }
    return;
  });

  $("#addProductContainer").on("click", "#shopifyAdd", function (e) {
    e.preventDefault();
    var itemQ = $("#itmQty").val();
    var itemV = $("#productVariants").val();
    var vName = $("#productVariants option:selected").text();
    var itemP = $("#shopifyPrice").text();
    $("#itemQuantity").val(itemQ);
    $("#variant_id").val(itemV);
    $("#variant_name").val(vName);
    $("#itemPrice").val(itemP);
    $("#addProductForm").trigger("submit");
  });

  $(document).on("click", "[data-display='modal']", function (e) {
    e.preventDefault();
    $("#cartModal").fadeIn("fast");
  });

  $(document).on("click", '[data-dismiss="modal"]', function (e) {
    e.preventDefault();
    $("#cartModal").fadeOut("fast");
  });

  $(document).on(
    "click",
    "#arrowCircleContainer, #categoryContainer",

    function (e) {
      e.stopImmediatePropagation();
      if (window.innerWidth < 992) {
        $("#arrowCircleContainer").toggleClass("rotate-180");
        $("#categoryMenu").stop().slideToggle("fast");
      } else {
        return;
      }
    }
  );

  $(document).on("change", "#mobileSubCategory", function (e) {
    //console.log(e.target.value);
    var tabTarget = document.getElementById(e.target.value + "-tab");
    tabTarget.click();
  });
});
